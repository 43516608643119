






























import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/sdk/api/Api.ts';
@Component({
  components: {
    InsBanner: () => import('@/components/base/InsBanner.vue'),
    InsAboutUs: () => import('@/components/business/home/InsAboutUs.vue'),
    InsHomeCat: () => import('@/components/business/home/InsHomeCat.vue'),
    CmxCmsList: () => import('@/components/business/home/InsCmsList.vue')
  }
})
export default class InsHome extends Vue {
  Metacontent: object = {};
  Metacontentkeyword: string = '';
  MetacontentDescription: string = '';

  get isMobile () {
    return this.$store.state.isMobile;
  }
  // created () {
  //   let keywords = document.createElement('meta');
  //   keywords.setAttribute('name', 'keywords');
  //   keywords.setAttribute('content', require('@/sdk/common/SeoData').seoItem['home'].keyword);
  //   document.head.appendChild(keywords);
  //   let description = document.createElement('meta');
  //   description.setAttribute('name', 'description');
  //   description.setAttribute('content', require('@/sdk/common/SeoData').seoItem['home'].description);
  //   document.head.appendChild(description);
  //   document.dispatchEvent(new Event('render-event'));
  // }
  created () {
    let titleTags:object = document.getElementsByTagName('title');
    titleTags[0].innerText = this.$t('MetaTitle.Hkfda');
    this.$Api.promotion.getMeta().then((result) => {
      this.Metacontent = result.Data;
      this.Metacontentkeyword = result.Data.Keyword;
      this.MetacontentDescription = result.Data.Description;

      let keywords = document.createElement('meta');
      keywords.setAttribute('name', 'keywords');
      keywords.setAttribute('content', this.Metacontentkeyword);
      document.head.appendChild(keywords);
      let description = document.createElement('meta');
      description.setAttribute('name', 'description');
      description.setAttribute('content', this.MetacontentDescription);
      document.head.appendChild(description);
      document.dispatchEvent(new Event('render-event'));
    });
  }
  mounted () {
    api
      .getData(1, 1)
      .then((res: any) => {
        setTimeout(() => {
          Vue.prototype.$HiddenLayer();
        }, 500);
      })
      .catch((err: any) => {
        // 请求失败后的处理函数
        console.log(err);
      });
  }
}
